var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("参加码管理")])]
      ),
      _vm.source === "校管家V"
        ? _c(
            "el-row",
            [
              _c("el-alert", {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  title: "该直播间渠道为 校管家V 生成学员参加码一致",
                  type: "warning",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: {
            width: "100%",
            "margin-bottom": "20px",
            "margin-top": "20px",
          },
          attrs: { data: _vm.dataList, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "学号",
              align: "center",
              width: "120",
              prop: "loginName",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "参加码", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { color: "#EB9316" } }, [
                      _vm._v(_vm._s(scope.row.student_code)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "直播间状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        disabled: !Boolean(scope.row.luid),
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.switchRoom(scope.row, scope.row.luSwitch)
                        },
                      },
                      model: {
                        value: scope.row.luSwitch,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "luSwitch", $$v)
                        },
                        expression: "scope.row.luSwitch",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.createCode },
            },
            [_vm._v("生成参加码")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small" },
              on: { click: _vm.batchOpen },
            },
            [_vm._v("批量开放")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small" },
              on: { click: _vm.batchClose },
            },
            [_vm._v("批量关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }