<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>进入直播间</span>
    </div>
    <!-- liveData:{{liveData}}
    <hr>
    code：{{code}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="130px">
      <template v-if="liveData.source === 'tms'">
        <el-form-item label="选择进入方式：" prop="type">
          <el-radio-group v-model="formData.type" size="mini">
            <el-radio-button :label="0">网页</el-radio-button>
            <el-radio-button :label="1">客户端</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择角色进入：" prop="role">
          <el-radio-group v-model="formData.role" size="mini" @change="handleChange">
            <el-radio-button :label="1">主讲</el-radio-button>
            <el-radio-button :label="2">助教</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="参加码：">
          <template v-if="code">
            <span v-text="code" style="color: #EB9316;"></span>
            <el-button
              type="warning"
              size="mini"
              plain
              v-clipboard:copy="code"
              v-clipboard:success="copy"
              v-clipboard:error="onError"
              style="margin-left: 20px;"
            >点击复制</el-button>
          </template>
        </el-form-item>
      </template>

      <template v-else>
        <el-form-item label="讲师参加码：">
          <span v-text="liveData.teacher_code" style="color: #EB9316;"></span>
          <el-button
            type="warning"
            size="mini"
            plain
            v-clipboard:copy="liveData.teacher_code"
            v-clipboard:success="copy"
            v-clipboard:error="onError"
            style="margin-left: 20px;"
          >点击复制</el-button>
        </el-form-item>
        <el-form-item label="助教参加码：">
          <span v-text="liveData.admin_code" style="color: #EB9316;"></span>
          <el-button
            type="warning"
            size="mini"
            plain
            v-clipboard:copy="liveData.admin_code"
            v-clipboard:success="copy"
            v-clipboard:error="onError"
            style="margin-left: 20px;"
          >点击复制</el-button>
        </el-form-item>
      </template>

      <el-form-item label="直播时间：">
        {{ liveData.start_time | formatDateStart
        }}{{ liveData.end_time | formatDateEnd }}
      </el-form-item>
    </el-form>

    <template v-if="liveData.source === 'tms'">
      <el-row slot="footer">
        <el-button type="primary" @click="saveLive" class="dialog-btn">确 定</el-button>
        <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
      </el-row>
    </template>
    <template v-else>
      <el-row slot="footer">
        <el-button type="primary" @click="saveLive" class="dialog-btn">点击进入直播间</el-button>
      </el-row>
    </template>

  </el-dialog>
</template>

<script>
import { getWebLiveEnter, getLiveRoomtnById } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    liveRoomId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      formData: {
        type: null,
        role: null
      },
      liveData: {},
      code: '',
      rules: {
        type: [{ required: true, message: '请选择进入方式', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'blur' }],
      }
    }
  },
  methods: {
    openDialog () {
      this.code = ''
      this.formData = {
        type: null,
        role: null
      }
      // this.getWebLiveEnter()
      this.getLiveRoomtnById()
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    copy (e) {
      window.$msg('复制成功')
    },
    onError (e) {
      window.$msg('复制失败')
    },

    handleChange(val) {
      if (val === 1) {
        this.code = this.liveData.teacher_code
      } else {
        this.code = this.liveData.admin_code
      }
    },

    async getLiveRoomtnById () {
      const res = await getLiveRoomtnById({
        liveRoomId: this.liveRoomId
      })
      this.liveData = res.body
    },

    saveLive () {
      if (this.liveData.source === 'tms') {
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.formData.type !== null && this.formData.role !== null) {
              getWebLiveEnter({
                liveRoomId: this.liveRoomId,
                userRole: this.formData.role
              }).then(res => {
                if (res.state === 'success') {
                  this.close()
                  if (this.formData.type === 0) {
                    window.open(`${res.body}`)
                  } else {
                    let client_url = 'baijiacloud://urlpath=' + res.body + '&token=token&ts=ts'
                    window.open(client_url)
                  }
                }
              })
            } else {
              this.close()
            }
          }
        })
      } else {
       window.open(`https://www.baijiayun.com/entry`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
