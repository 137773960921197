var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("选择课堂任务")])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { model: _vm.formData, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课堂任务：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    placeholder: "请选择课堂任务（可多选）",
                  },
                  on: { change: _vm.forceUpdate },
                  model: {
                    value: _vm.formData.task,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "task", $$v)
                    },
                    expression: "formData.task",
                  },
                },
                _vm._l(_vm.taskList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.seleSave } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }