<template>
  <el-dialog
    title="修改学员密码"
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    append-to-body
  >
    <el-form style="padding:30px 50px;padding-bottom: 0;">
      <el-form-item label="学员姓名:" :label-width="formLabelWidth">
        <p style="margin:0 0 0 5px;text-align: left;">{{resetUse.userName}}</p>
      </el-form-item>
      <el-form-item label="学号:" :label-width="formLabelWidth">
        <p style="margin:0 0 0 5px;text-align: left;">{{resetUse.loginName}}</p>
      </el-form-item>
      <el-form-item label="新密码：" :label-width="formLabelWidth">
        <el-input
          v-model="resetPwd1"
          autocomplete="off"
          placeholder="请输入新密码"
          style="width:25vw;"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" :label-width="formLabelWidth">
        <el-input
          v-model="resetPwd2"
          autocomplete="off"
          placeholder="请再次输入新密码"
          style="width:25vw;"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="resetPwdSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { changeStuPass} from '@/api/senate/class'
  export default {
    name: "changePassword",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      resetUse: {
        default: () => {
          return {};
        },
        type: Object
      }
    },
    data() {
      return {
        formLabelWidth: "100px",
        resetPwd1: "",
        resetPwd2: ""
      };
    },
    methods: {
      openDialog() {
        this.resetPwd1 = ""
        this.resetPwd2 = ""
        // console.log("rolename"+JSON.stringify(this.resetUse))
      },
      // 修改学员密码
      async changeStuPass (userid, newPassword) {
        const res = await changeStuPass({
          id: userid,
          pwd: newPassword
        })
        if (res.state === "success") {
          $msg("密码修改成功");
          this.close()
        } else {
          $msg(res.errMsg, 2);
        }
      },

      close() {
        this.$emit("close");
      },
      // 提交修改
      resetPwdSave() {
        if (this.resetPwd1  === "" && this.resetPwd2 === "") {
          $msg("密码不能为空", 1);
          return false;
        }
        if (this.resetPwd1 && this.resetPwd1 === this.resetPwd2) {
          this.changeStuPass(this.resetUse.user_id, this.resetPwd1);
        } else {
          $msg("两次密码输入不一致", 1);
          return false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dialog-footer {
    text-align: left;
    margin-left: 50px;
    margin-bottom: 35px;
  }

  /deep/ .el-form-item {
    text-align: left;
    margin-bottom: 15px;
  }
</style>
