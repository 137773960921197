var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.LiveDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "直播间名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入直播间名称(50个字符以内)",
                  clearable: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开始时间：", prop: "startTime" } },
            [
              _c("el-date-picker", {
                staticStyle: { float: "left" },
                attrs: {
                  type: "datetime",
                  "picker-options": _vm.expireTimeOption,
                  disabled: !_vm.isTest,
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择开始时间",
                },
                on: { change: _vm.startTimeChange },
                model: {
                  value: _vm.formData.startTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "startTime", $$v)
                  },
                  expression: "formData.startTime",
                },
              }),
              _vm.formData.startTime && _vm.isTest
                ? _c(
                    "el-row",
                    { staticStyle: { float: "left" } },
                    [
                      _vm.commonTimeOptions.length !== 0
                        ? _c(
                            "el-dropdown",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                trigger: "click",
                                disabled: !_vm.isTest,
                              },
                              on: { command: _vm.commonTimeChange },
                              model: {
                                value: _vm.commonTime,
                                callback: function ($$v) {
                                  _vm.commonTime = $$v
                                },
                                expression: "commonTime",
                              },
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(" 常用时间段"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _vm._l(
                                    _vm.commonTimeOptions,
                                    function (item) {
                                      return _c(
                                        "el-dropdown-item",
                                        { attrs: { command: item } },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [
                                              _vm._v(
                                                _vm._s(item.startTime) + "~"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v(_vm._s(item.endTime))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              staticStyle: {
                                                float: "right",
                                                "margin-left": "15px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteCommonTime(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "10px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.gotoAddCommenTime },
                                    },
                                    [_vm._v("添加常用时间段")]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.gotoAddCommenTime },
                            },
                            [_vm._v("添加常用时间段")]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播时长：", prop: "timeDuration" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "20%" },
                attrs: {
                  disabled: !_vm.isTest,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                on: { change: _vm.timeDurationhandleChange },
                model: {
                  value: _vm.formData.timeDuration,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "timeDuration", $$v)
                  },
                  expression: "formData.timeDuration",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-right": "30px",
                  },
                },
                [_vm._v("分钟")]
              ),
              _vm.isTest
                ? _c(
                    "el-dropdown",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { trigger: "click" },
                      on: { command: _vm.timeDurationSelectChange },
                      model: {
                        value: _vm.timeDurationSelect,
                        callback: function ($$v) {
                          _vm.timeDurationSelect = $$v
                        },
                        expression: "timeDurationSelect",
                      },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(" 常用时长"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.timeDurationOptions, function (item) {
                          return _c(
                            "el-dropdown-item",
                            { attrs: { command: item.value } },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结束时间：" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { min: 0, disabled: "" },
                model: {
                  value: _vm.formData.endTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "endTime", $$v)
                  },
                  expression: "formData.endTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播间类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择直播间类型",
                    clearable: "",
                    disabled: _vm.isTest || _vm.liveData !== null,
                  },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.roomType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播间渠道：", prop: "source" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择直播间渠道",
                    clearable: "",
                    disabled: _vm.liveData !== null,
                  },
                  model: {
                    value: _vm.formData.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "source", $$v)
                    },
                    expression: "formData.source",
                  },
                },
                _vm._l(_vm.sourceType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: {
                      label: item.label,
                      disabled: item.disabled,
                      value: item.value,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.formData.type === 4
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "开班班型：", prop: "max_users" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          disabled: _vm.isTest,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.formData.max_users,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "max_users", $$v)
                          },
                          expression: "formData.max_users",
                        },
                      },
                      _vm._l(_vm.typeOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "台下学生：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput: "value=value.replace(/[^\\d]/g,'')",
                        clearable: "",
                        placeholder: "请输入台下学生数,最大为300",
                        disabled: _vm.isTest,
                      },
                      on: { input: _vm.isNum },
                      model: {
                        value: _vm.formData.max_backup_users,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "max_backup_users", $$v)
                        },
                        expression: "formData.max_backup_users",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveLive } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-common-time", {
        attrs: { show: _vm.showCommomTime },
        on: { close: _vm.closeCommonTimeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }