<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择课堂任务</span>
    </div>
    <el-form :model="formData" ref="formData" label-width="100px">
      <el-form-item label="课堂任务：">
        <el-select v-model="formData.task" multiple placeholder="请选择课堂任务（可多选）" @change="forceUpdate">
          <el-option
            v-for="item in taskList"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="seleSave">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { listOnly, mapCourseUnits } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonId: {
      default: '',
      type: String
    },
    classId: {
      default: '',
      type: String
    },
    courseId: {
      default: '',
      type: String
    },
    courseUnitIdList: {
      default: () => {
        return []
      },
      type: Array
    },
  },
  data () {
    return {
      formData: {},
      taskList: []
    }
  },
  methods: {
    forceUpdate () {
      this.$forceUpdate()
    },
    openDialog () {
      this.formData = {}
      this.formData.task = this.courseUnitIdList
      this.listOnly()
    },

    async listOnly () {
      const res = await listOnly({
        pageNum: 1,
        pageSize: 1000,
        status: '已启用',
        classId: this.classId,
        courseId:this.courseId,
      })
      this.taskList = res.body.list
    },

    close () {
      this.$emit('close')
    },

    seleSave () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          mapCourseUnits({
            lessonId: this.lessonId,
            courseUnitIds: this.formData.task
          }).then(res => {
            if (res.state === 'success') {
              this.close()
              window.$msg('操作成功')
              this.$emit('updateView')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
