<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="LiveDialogTitle"></span>
    </div>
    <!-- 直播间数据formData：{{formData}}
    <hr />
    直播间名称：{{liveName}}
    <hr />
    直播间数据：{{liveData}}
    <hr />
    排课数据：{{lessonData}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="直播间名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入直播间名称(50个字符以内)" clearable @input="$forceUpdate()"></el-input>
      </el-form-item>
      <el-form-item label="开始时间：" prop="startTime">
        <el-date-picker
          v-model="formData.startTime"
          type="datetime"
          style="float: left"
          :picker-options="expireTimeOption"
          :disabled="!isTest"
          @change="startTimeChange"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始时间">
        </el-date-picker>
        <el-row style="float: left" v-if="formData.startTime && isTest">
          <el-dropdown trigger="click" style="margin-left: 10px;"   :disabled="!isTest" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">
            <span class="el-dropdown-link">
              常用时间段<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item"   v-for="item in commonTimeOptions">
                <span style="float: left">{{ item.startTime }}~</span>
                <span style="float: left">{{ item.endTime }}</span>
                <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>
              </el-dropdown-item>
              <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
<!--          <el-select  :disabled="!isTest"  style="margin-left: 10px;" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @change="commonTimeChange" placeholder="常用时间段">-->
<!--            <el-option-->
<!--              v-for="item in commonTimeOptions"-->
<!--              :key="item.label"-->
<!--              :label="item.label"-->
<!--              :value="item.label"-->
<!--            >-->
<!--              <span style="float: left">{{ item.startTime }}~</span>-->
<!--              <span style="float: left">{{ item.endTime }}</span>-->
<!--              <span class="el-dropdown-link" style="float: right" @click.stop="deleteCommonTime(item)">删除</span>-->
<!--            </el-option>-->
<!--            <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--          </el-select>-->
<!--          <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
        </el-row>
      </el-form-item>
      <el-form-item label="直播时长："  prop="timeDuration">
        <el-input-number  :disabled="!isTest" style="width: 20%;" :precision="0" v-model="formData.timeDuration" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>
        <span style="margin-left: 10px;margin-right: 30px;">分钟</span>
        <el-dropdown trigger="click" style="margin-left: 10px;"  v-if ="isTest"  v-model="timeDurationSelect"  @command="timeDurationSelectChange">
            <span class="el-dropdown-link">
              常用时长<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value"   v-for="item in timeDurationOptions">
              <span style="float: left">{{ item.label}}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
<!--        <span style="margin-right: 10px;">常用时长</span>-->
<!--        <el-select v-model="timeDurationSelect"  :disabled="!isTest" placeholder="快捷选择时长" @change="timeDurationSelectChange" clearable>-->
<!--          <el-option-->
<!--            v-for="item in timeDurationOptions"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
      </el-form-item>
      <el-form-item label="结束时间：">
        <el-input style="width: 30%;" v-model="formData.endTime" :min="0" disabled></el-input>
<!--        <el-time-picker-->
<!--          v-model="formData.startTime"-->
<!--          value-format="HH:mm:ss"-->
<!--          format='HH:mm'-->
<!--          class="date-box"-->
<!--          :picker-options="{selectableRange:`00:00:00 -${formData.endTime ? formData.endTime+':00' : '23:59:00'}`}"-->
<!--          clearable-->
<!--          :disabled="!isTest"-->
<!--          placeholder="直播起始时间">-->
<!--        </el-time-picker>-->
<!--        <span>—</span>-->
<!--        <el-time-picker-->
<!--          style="font-size: 16px;"-->
<!--          v-model="formData.endTime"-->
<!--          clearable-->
<!--          :disabled="!isTest"-->
<!--          :picker-options="{selectableRange:`${formData.startTime ? formData.startTime+':00' : '00:00:00'}-23:59:00`}"-->
<!--          format='HH:mm'-->
<!--          value-format="HH:mm:ss"-->
<!--          placeholder="直播结束时间">-->
<!--        </el-time-picker>-->
      </el-form-item>
      <el-form-item label="直播间类型：" prop="type">
        <el-select v-model="formData.type" placeholder="请选择直播间类型" clearable :disabled="isTest || liveData !== null" @change="handleChange">
          <el-option
            v-for="item in roomType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="直播间渠道：" prop="source">
        <el-select v-model="formData.source" placeholder="请选择直播间渠道" clearable :disabled="liveData !== null">
          <el-option
            v-for="item in sourceType"
            :key="item.value"
            :label="item.label"
            :disabled="item.disabled"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="formData.type === 4">
        <el-form-item label="开班班型：" prop="max_users">
          <el-select v-model="formData.max_users" placeholder="请选择" @change="$forceUpdate()" clearable :disabled="isTest">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="台下学生：">
          <el-input
            oninput="value=value.replace(/[^\d]/g,'')"
            @input="isNum"
            clearable
            v-model="formData.max_backup_users"
            placeholder="请输入台下学生数,最大为300"
            :disabled="isTest"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="saveLive">保 存</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
    <add-common-time :show="showCommomTime" @close="closeCommonTimeDialog" ></add-common-time>
  </el-dialog>
</template>

<script>
/**
* @module @/components/senate/add-live
* @author libin
* @attribute showdialog弹框状态，true（显示）| false(关闭)
* @attribute liveData（直播间数据，根据有无直播间数据是否为null判断用户是新增直播间 | 编辑直播间操作）编辑直播间不可以修改直播间类型、直播间创建的时间不能小于系统当前时间（常规、试听 直播间默认为排课时间，不可修改）测试直播间，直播时间随意更改
* @attribute liveName（直播间名称）
* @attribute lessonData（排课相关数据、常规课程，新增直播间的起止时间默认为排课的时间、试听课程，直播间的起止时间不受排课时间的限制）
* @attribute isTest（是否为测试直播间、测试直播间默认1V1、小班课，台下学生10人）
* @attribute studentIds（试听排课的学员）
* @description 直播间的新增&&编辑 dialog 组件 <add-live :show="dialogLive" :liveName="lessonLiveName" :liveData="liveData" :lessonData="lessonLiveData" @close="dialogLive=false,liveData=null,lessonLiveData=null" @addLiveSave="addLiveSave" @editLiveSave="editLiveSave"></add-live>
* */

import { getLiveRoomtnById } from '@/api/senate/class'
import { formatTate } from '@/utils/formatDate'
import {listTeacherLevel,deleteUserPeriod} from '@/api/user'
import addCommonTime from '@/components/senate/add-commontime'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    liveData: {
      default: () => {
        return {}
      },
      type: Object
    },
    liveName: {
      default: '',
      type: String
    },
    lessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
    isTest: {
      default: false,
      type: Boolean
    },
    isLessonReCreate: {
      default: false,
      type: Boolean
    }
  },
  components: {
    addCommonTime,
  },
  data () {
    return {
      commonTimeOptions:[],
      showCommomTime:false,
      commonTime:'',
      LiveDialogTitle: '',
      formData: {},
      rules: {
        title: [
          { required: true, message: '请填写直播见名称', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
        max_users: [
          { required: true, message: '请选择开班班型', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        timeDuration: [
          { required: true, message: '请选择直播时长', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择直播间类型', trigger: 'change' }
        ],
        source: [
          { required: true, message: '请选择直播间渠道', trigger: 'change' }
        ]
      },
      sourceType: [{ // 直播间渠道
        value: '校管家V',
        label: '校管家V'
      }, {
        value: 'tms',
        label: 'tms',
        disabled: true
      }],
      roomType: [{ // 直播间类型
        value: 4,
        label: '小班课'
      }, {
        value: 2,
        label: '大班课'
      }],
      typeOptions: [ // 班型
        {
          value: 1,
          label: '1V1'
        },
        {
          value: 6,
          label: '1V6'
        }
      ],
      timeDurationSelect:'',
      timeDurationOptions: [ // 时长
        {
          value: 30,
          label: '30分钟'
        },
        {
          value: 45,
          label: '45分钟'
        },
        {
          value: 60,
          label: '60分钟'
        },
        {
          value: 90,
          label: '90分钟'
        },
        {
          value: 120,
          label: '120分钟'
        },
        {
          value: 180,
          label: '180分钟'
        }
      ],
      expireTimeOption: {
        disabledDate (date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
    }
  },
  methods: {
    commonTimeChange(command) {
      if(this.formData.startTime !== undefined){
        this.formData.startTime = this.formData.startTime.substring(0,10)+' '+command.startTime+':00'
        this.formData.endTime = this.formData.startTime.substring(0,10)+' '+command.endTime+':00'
        this.formData.timeDuration = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.startTime)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.timeDuration)) {
            this.timeDurationSelect = this.formData.timeDuration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
      }
    },
    async deleteUserPeriod(row) {
      const response = await deleteUserPeriod({
        usperiodId:row.id
      })
      if(response && response.state === 'success') {
        window.$msg('删除成功')
        this.listTeacherLevel()
        this.commonTime = ''
        this.$forceUpdate()
      }
    },
    async listTeacherLevel() {
      const response = await listTeacherLevel()
      if(response && response.state === 'success') {
        this.commonTimeOptions = response.body
      }
      this.commonTimeOptions.forEach((item)=>{
        item.label = item.startTime+'~'+item.endTime
      })
    },
    closeCommonTimeDialog() {
      this.showCommomTime = false
      this.listTeacherLevel()
      this.$forceUpdate()
    },
    deleteCommonTime(row) {
      this.$confirm('确定删除此常用时间段吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserPeriod(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    gotoAddCommenTime() {
      this.showCommomTime = true
    },
    startTimeChange() {
      let oldTime = 0
      this.commonTime = ''
      if(this.formData.timeDuration !== undefined) {
        oldTime = (new Date(this.formData.startTime)).getTime() + parseInt(this.formData.timeDuration) * 60 * 1000;
      }else {
        oldTime = (new Date(this.formData.startTime)).getTime()
      }
      if(this.formData.startTime !== null) {
        this.formData.endTime = formatTate(oldTime);
      }else {
        this.formData.endTime = undefined
      }
      this.$forceUpdate()
    },
    timeDurationhandleChange() {
      this.commonTime = ''
      let flag = false
      this.timeDurationOptions.forEach((item) => {
        if (item.value === parseInt(this.formData.timeDuration)) {
          this.timeDurationSelect = this.formData.timeDuration
          flag = true
        }
      })
      if(!flag) {
        this.timeDurationSelect = ''
      }
      if(this.formData.startTime !== undefined) {
        var oldTime = (new Date(this.formData.startTime)).getTime() + parseInt(this.formData.timeDuration) * 60 * 1000;
        this.formData.endTime = formatTate(oldTime);
        this.$forceUpdate()
      }
    },
    timeDurationSelectChange(command) {
      this.timeDurationSelect = command
      this.commonTime = ''
      this.formData.timeDuration = this.timeDurationSelect

      if(this.formData.startTime !== undefined && this.formData.startTime !== null) {
        if( this.formData.timeDuration !== '') {
          var oldTime = (new Date(this.formData.startTime)).getTime() + parseInt(this.formData.timeDuration) * 60 * 1000;
          this.formData.endTime = formatTate(oldTime);
          this.$forceUpdate()
        }else {
          this.formData.endTime = this.formData.startTime
        }
      }else{
        this.formData.endTime = ''
      }
    },
    openDialog () {
      this.listTeacherLevel()
      this.timeDurationSelect = ''
      this.formData = {}
      if (this.liveData !== null) {
        console.log(JSON.stringify(this.liveData))
        this.LiveDialogTitle = '编辑直播间'
        this.getLiveRoomtnById()
      } else {
        this.LiveDialogTitle = '新增直播间'
        if (!this.isTest) {
          if(this.isLessonReCreate) {
            this.lessonData.startTime = this.lessonData.start_time
            this.lessonData.endTime = this.lessonData.end_time
          }
          this.formData.date = this.lessonData.startTime ? this.lessonData.startTime.substring(0 ,10) : this.lessonData.startTime.substring(0, 10)
          this.formData.startTime = this.lessonData.startTime
          this.formData.endTime = this.lessonData.endTime.substring(0,19)
          this.formData.lesson_id = this.lessonData.id ? this.lessonData.id : this.lessonData.lessonId
          this.formData.timeDuration = ((new Date(this.lessonData.endTime)).getTime() - (new Date(this.lessonData.startTime)).getTime()) / 60 / 1000
          let flag = false
          this.timeDurationOptions.forEach((item) => {
            if (item.value === parseInt(this.formData.timeDuration)) {
              this.timeDurationSelect = this.formData.timeDuration
              flag = true
            }
          })
          if(!flag) {
            this.timeDurationSelect = ''
          }
        } else {
          this.formData.type = 4
          this.formData.lesson_id = this.lessonData.lessonId
          this.formData.max_users = 1
          this.formData.max_backup_users = 10
        }
      }
      this.formData.title = this.liveName
    },

    // 根据id获取直播间信息
    async getLiveRoomtnById () {
      const res = await getLiveRoomtnById({
        liveRoomId: this.liveData.liveId
      })
      this.formData = Object.assign({}, res.body)
      this.formData.date = res.body.start_time.substring(0 ,10)
      this.formData.startTime = res.body.start_time.substring(0 ,19)
      this.formData.endTime = res.body.end_time

      this.formData.timeDuration = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.startTime)).getTime()) / 60 / 1000
      let flag = false
      this.timeDurationOptions.forEach((item) => {
        if (item.value === parseInt(this.formData.timeDuration)) {
          this.timeDurationSelect = this.formData.timeDuration
          flag = true
        }
      })
      if(!flag) {
        this.timeDurationSelect = ''
      }
      if (res.body.max_users === 0) {
        this.formData.max_users = ''
      }
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    handleChange(val) {
      if (val === 4) {
        this.formData.max_backup_users = 10
      }
    },

    isNum () {
      this.$forceUpdate()
      if (Number(this.formData.max_backup_users) > 300) {
        window.$msg('台下学生数不能大于300', 2)
        this.formData.max_backup_users = ''
      }
    },

    saveLive () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.liveData !== null) {
            this.$emit('editLiveSave', this.formData)
          } else {
            this.$emit('addLiveSave', this.formData)
          }
          this.close()
        }
      })
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/style/dialog.scss";
</style>
