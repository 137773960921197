<template>
  <el-dialog
    title="排课学员管理"
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >

    <el-table :data="stuList">
      <el-table-column label="学号" align="center">
        <template slot-scope="scope">
          <span>
            {{ scope.row.loginName }}
            <span v-if="scope.row.source === '调课'">[临时调课]</span>
            <span v-else-if="scope.row.source === '调班'">[调班]</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="性别" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sex }}</span>
        </template>
      </el-table-column>
      <el-table-column label="来源" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.source }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="350">
        <template slot-scope="scope">
          <el-button
            plain
            size="small"
            type="primary"
            @click="studentCourse(scope.row)"
            v-has="'class:studentcourse'"
            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
          >学员课程</el-button>
          <el-button size="small" type="primary" plain @click="changePassword(scope.row)" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">修改密码</el-button>
<!--          <el-button-->
<!--            size="small"-->
<!--            type="danger"-->
<!--            plain-->
<!--            @click="delStu(scope.row)"-->
<!--            v-has="'class:delLessonStu'"-->
<!--            v-if="!product_id"-->
<!--            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01')"-->
<!--          >删除</el-button>-->
          <el-button
            v-if="lessonData.finished_status !== '已上课'"
            size="small"
            type="info"
            plain
            v-has="'class:thembChangeLesson'"
            @click="temporaryStu(scope.row)"
            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
          >临时调课</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改密码dialog -->
    <change-password :show="dialogPassword" @close="dialogPassword=false" :resetUse="resetUse"></change-password>
    <!-- 学员课程dialog -->
    <student-course
      :resetUse="studentCourseObj"
      :show="dialogStuCourse"
      @close="dialogStuCourse=false"
    ></student-course>
    <!-- 临时调课 -->
    <temporary-class :show="temporaryDialog" @close="temporaryDialog=false" :lesson_id="lesson_id" :student_id="student_id" @updateView="updateView" :lessonData="lessonData"></temporary-class>
  </el-dialog>
</template>

<script>
import changePassword from '../change-password/index'
import studentCourse from '../detail-pane/student-course'
import { getLessionStudentMessage, delLessonStudent } from '@/api/senate/class'
import temporaryClass from '@/components/senate/temporary-class'

export default {
  name: 'courseClass',
  components: {
    changePassword,
    studentCourse,
    temporaryClass
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
    product_id: {
      default: '',
      type: String
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      dialogPassword: false,
      lesson_id: '',
      resetUse: {},
      stuList: [],
      studentCourseObj: null,
      dialogStuCourse: false,
      temporaryDialog: false,
      student_id: ''
    }
  },
  methods: {
    async getLessionStudentMessage () {
      const res = await getLessionStudentMessage({
        lesson_id: this.lesson_id
      })
      if (res.state === 'success') {
        this.stuList = res.body
      }
    },

    openDialog () {
      this.lesson_id = this.lessonData.id
      this.getLessionStudentMessage()
    },

    close () {
      this.$emit('close')
      this.$emit('updateView')
    },

    changePassword (row) {
      this.resetUse = row
      row.userName = row.name
      row.user_id = row.student_id
      this.dialogPassword = true
    },

    studentCourse (row) {
      this.studentCourseObj = row
      this.studentCourseObj.user_id = this.studentCourseObj.student_id
      this.studentCourseObj.class_id = this.lessonData.class_id
      this.dialogStuCourse = true
    },

    async delLessonStudent (row) {
      const res = await delLessonStudent({
        lessonId: this.lesson_id,
        studentIds: [row.student_id]
      })
      if (res.state === 'success') {
        window.$msg('删除成功')
        this.getLessionStudentMessage()
      } else {
        window.$msg(res.errMsg, 2)
      }
    },

    // 删除排课学员
    delStu (row) {
      this.$confirm('是否删除当前成员？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delLessonStudent(row)
        })
        .catch(() => {
          return false
        })
    },

    // 临时调课
    temporaryStu (row) {
      // console.log('临时调课', row)
      this.student_id = row.student_id
      this.temporaryDialog = true
    },

    updateView () {
      this.getLessionStudentMessage()
      // this.$emit('updateView')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
