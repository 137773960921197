<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>参加码管理</span>
    </div>
    <el-row v-if="source === '校管家V'">
      <el-alert
        style="margin-top: 20px;"
        title="该直播间渠道为 校管家V 生成学员参加码一致"
        type="warning">
      </el-alert>
    </el-row>
    <el-table
      ref="table"
      :data="dataList"
      style="width: 100%;margin-bottom: 20px;margin-top: 20px;"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="学号" align="center" width="120" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="参加码" align="center">
        <template slot-scope="scope">
          <span style="color: #EB9316">{{scope.row.student_code}}</span>
        </template>
      </el-table-column>
      <el-table-column label="直播间状态" align="center">
        <template slot-scope="scope">
          <!-- scope.row.status：{{scope.row.status}} -->
          <el-switch
            v-model="scope.row.luSwitch"
            @change="switchRoom(scope.row, scope.row.luSwitch)"
            :disabled="!Boolean(scope.row.luid)"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-button type="primary" size="small" @click="createCode">生成参加码</el-button>
      <el-button type="info" size="small" @click="batchOpen">批量开放</el-button>
      <el-button type="info" size="small" @click="batchClose">批量关闭</el-button>
    </el-row>

    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination> -->

  </el-dialog>
</template>

<script>
import { getLessonStudetnByLiveRoom, createCodeBliveRoom, switchLiveRoom,switchLuBatch } from '@/api/senate/class'
import { getLiveRoomtnById } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    liveRoomId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      multipleSelection: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      dataList: [],
      source: ''
    }
  },
  methods: {
    openDialog () {
      this.source = ''
      this.getLiveRoomtnById()
      this.getLessonStudetnByLiveRoom()
    },

    // 根据id获取直播间信息
    async getLiveRoomtnById () {
      const res = await getLiveRoomtnById({
        liveRoomId: this.liveRoomId
      })
      this.source = res.body.source
    },

    async getLessonStudetnByLiveRoom () {
      const res = await getLessonStudetnByLiveRoom({
        liveRoomId: this.liveRoomId,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true
      })
      this.dataList = res.body
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      // this.getLiveList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      // this.getLiveList()
    },

    handleSelectionChange (val) {
      this.multipleSelection = val
    },

    switchRoom (row, val) {
      switchLiveRoom({
        luid: row.luid,
        status: val
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.getLessonStudetnByLiveRoom()
        }
      })
    },
    switchLuBatch(val) {
      let jsonArray = []
      this.multipleSelection.forEach(item => {
        jsonArray.push(item.luid)
      })
      switchLuBatch({
        luidSet:jsonArray,
        status: val
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.getLessonStudetnByLiveRoom()
        }
      })
    },
    batchOpen() {
      if (this.multipleSelection.length !== 0) {
        let flag = flag
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if(this.multipleSelection[i].luid === null) {
            flag = true
          }
        }
        if(flag) {
          window.$msg('当前选择的数据有未生成参加码的，请先生成参加码', 2)
        }else {
          this.switchLuBatch(1)
        }
      } else {
        window.$msg('请先选择用户', 2)
      }
    },
    batchClose() {
      if (this.multipleSelection.length !== 0) {
        let flag = flag
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if(this.multipleSelection[i].luid === null) {
            flag = true
          }
        }
        if(flag) {
          window.$msg('当前选择的数据有未生成参加码的，请先生成参加码', 2)
        }else {
          this.switchLuBatch(0)
        }
      } else {
        window.$msg('请先选择用户', 2)
      }
    },
    createCode () {
      if (this.multipleSelection.length !== 0) {
        let codeData = []
        this.multipleSelection.forEach((item, index) => {
          codeData.push({ liveRoomId: this.liveRoomId, userId: item.student_id })
        })
        createCodeBliveRoom(JSON.stringify(codeData)).then(res => {
          if (res.state === 'success') {
            window.$msg('生成成功')
            this.getLessonStudetnByLiveRoom()
          }
        })
        // createCodeBliveRoom(`'${JSON.stringify(codeData)}'`)
      } else {
        window.$msg('请先选择用户', 2)
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.table.clearSelection()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";

/deep/ .el-dialog__body {
  padding-top: 0 !important;
}
</style>
