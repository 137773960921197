<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    append-to-body
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>临时调课</span>
    </div>
    <!-- {{lessonData.finished_status === '已上课'}} -->
    <el-row>
      <el-select v-model="schoolValue" placeholder="请选择校区" class="tb-smselect">
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-input
        v-model.trim="classNameValue"
        placeholder="请输入班级名称"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-date-picker
        class="tb-picker"
        v-model="lessonTime"
        type="datetimerange"
        start-placeholder="排课开始日期"
        end-placeholder="排课结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00']"
      ></el-date-picker>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>

      <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="班级" align="center" prop="className"></el-table-column>
      <el-table-column label="上课时间" align="center" prop="startTime">
        <template slot-scope="scope">
          <span>{{scope.row.startTime | formatDateStart}}</span>
          <span>{{scope.row.endTime | formatDateEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button @click="adjustTo(scope.row)" plain size="small" type="primary" :disabled="lessonData.finished_status === '已上课'">调 入</el-button>
        </template>
      </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import { getCampusAll, listLessonForJoin, changeLesson } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lesson_id: {
      default: '',
      type: String
    },
    student_id: {
      default: '',
      type: String
    },
    lessonData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      schoolValue: '',
      schoolArray: [],
      classNameValue: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      adjustDialog: false,
      classIdTo: '',
      lessonTime: ''
    }
  },
  methods: {
    openDialog () {
      this.getCampusAll()
      this.listLessonForJoin()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listLessonForJoin()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listLessonForJoin()
    },
    search () {
      this.pagination.currentPage = 1
      this.listLessonForJoin()
    },
    reset () {
      this.schoolValue = ''
      this.classNameValue = ''
    },

    adjustTo (row) {
      console.log('adjustTo', row)
      this.$confirm('确认调入本次排课？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await changeLesson({
            lessonIdFrom: this.lesson_id,
            lessonIdTo: row.id,
            studentId: this.student_id
          })
          window.$msg('调入成功')
          this.close()
          this.$emit('updateView')
        })
    },

    // 获取全部校区
    async getCampusAll () {
      const res = await getCampusAll()
      this.schoolArray = res.body
    },

    // 获取可调入的排课列表
    async listLessonForJoin () {
      const res = await listLessonForJoin({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        lessonIdFrom: this.lesson_id, // 原排课id
        className: this.classNameValue,
        campusId: this.schoolValue,
        exceptClassIds: [this.$route.query.classID],
        minStartTime: this.lessonTime ? this.lessonTime[0] : '',
        maxStartTime: this.lessonTime ? this.lessonTime[1] : ''
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../style/dialog.scss";
</style>
