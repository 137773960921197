var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("进入直播间")])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _vm.liveData.source === "tms"
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "选择进入方式：", prop: "type" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.formData.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "type", $$v)
                          },
                          expression: "formData.type",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 0 } }, [
                          _vm._v("网页"),
                        ]),
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("客户端"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "选择角色进入：", prop: "role" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.formData.role,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "role", $$v)
                          },
                          expression: "formData.role",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("主讲"),
                        ]),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("助教"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "参加码：" } },
                  [
                    _vm.code
                      ? [
                          _c("span", {
                            staticStyle: { color: "#EB9316" },
                            domProps: { textContent: _vm._s(_vm.code) },
                          }),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.code,
                                  expression: "code",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.copy,
                                  expression: "copy",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onError,
                                  expression: "onError",
                                  arg: "error",
                                },
                              ],
                              staticStyle: { "margin-left": "20px" },
                              attrs: {
                                type: "warning",
                                size: "mini",
                                plain: "",
                              },
                            },
                            [_vm._v("点击复制")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: "讲师参加码：" } },
                  [
                    _c("span", {
                      staticStyle: { color: "#EB9316" },
                      domProps: {
                        textContent: _vm._s(_vm.liveData.teacher_code),
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.liveData.teacher_code,
                            expression: "liveData.teacher_code",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.copy,
                            expression: "copy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "warning", size: "mini", plain: "" },
                      },
                      [_vm._v("点击复制")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "助教参加码：" } },
                  [
                    _c("span", {
                      staticStyle: { color: "#EB9316" },
                      domProps: {
                        textContent: _vm._s(_vm.liveData.admin_code),
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.liveData.admin_code,
                            expression: "liveData.admin_code",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.copy,
                            expression: "copy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "warning", size: "mini", plain: "" },
                      },
                      [_vm._v("点击复制")]
                    ),
                  ],
                  1
                ),
              ],
          _c("el-form-item", { attrs: { label: "直播时间：" } }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("formatDateStart")(_vm.liveData.start_time)) +
                _vm._s(_vm._f("formatDateEnd")(_vm.liveData.end_time)) +
                " "
            ),
          ]),
        ],
        2
      ),
      _vm.liveData.source === "tms"
        ? [
            _c(
              "el-row",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "dialog-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.saveLive },
                  },
                  [_vm._v("确 定")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "dialog-btn",
                    attrs: { type: "info" },
                    on: { click: _vm.close },
                  },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "el-row",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "dialog-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.saveLive },
                  },
                  [_vm._v("点击进入直播间")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }